<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-1  d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage students</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Students
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('user', 'create')" @click="createStudent()" class="mt-4 btn btn-primary"
                    style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add student
                  </v-btn>&nbsp;&nbsp;
                  <b-dropdown size="sm" variant="link"  v-if="checkIsAccessible('import-export', 'import-student') || checkIsAccessible('import-export', 'export-student')"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4" no-caret
                    right no-flip>
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover">

                      <b-dropdown-text tag="div" class="navi-item"
                        v-if="checkIsAccessible('import-export', 'import-student')">
                        <a @click="import_excel = !import_excel" class="navi-link">
                          <span class="navi-icon">
                            <i class="fa fa-file-excel"></i>
                          </span>
                          <span class="navi-text">Import</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item"
                        v-if="checkIsAccessible('import-export', 'import-student')">
                        <a @click="exportData" class="navi-link">
                          <span class="navi-icon">
                            <i class="fa fa-file-csv"></i>
                          </span>
                          <span class="navi-text">Export</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item"
                        v-if="checkIsAccessible('import-export', 'import-student')">
                        <a @click="exportParentDetails" class="navi-link">
                          <span class="navi-icon">
                            <i class="fa fa-file-csv"></i>
                          </span>
                          <span class="navi-text">Export parent details</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5  py-5 d-sm-flex justify-content-start" v-if="import_excel">
              <div class="row px-4">
                <div class="col-12">
                  <h4>Upload file to update student details</h4>
                </div>
                <div class="col-8">
                  <v-file-input outlined dense v-model="excel_file" accept=".xlsx, .xls, .csv"
                                label="File input"></v-file-input>
                </div>
                <div class="col-4">
                  <v-btn @click="importStudent" :loading="isLoading" :disabled="!excel_file"
                         class="btn btn-primary ml-2 text-white">Import
                  </v-btn>
                  <v-btn @click="cancelStudentImport"
                         class="btn btn-primary ml-2 text-white">Cancel
                  </v-btn>
                </div>
                <div class="col-12 text-xs">

                </div>
                  <div class="col-12 text-xs">
                      <b>Note</b>
                      <ul class="ml-3">
                          <li> Sample excel file can be downloaded for individual <a @click="goToClass" target="_blank">class</a></li>
                          <li> Student will be updated only.It will not created new student from import student.</li>
                          <li> Following fields are updated on bulk update.
                              <code> Registration No., Symbol No. First name, Middle Name, Last name, ID Card address.</code>
                              Rest of the fields in excel fields are ignored</li>
                      </ul>
                </div>
              </div>
           </div>
            <div class="search-wrapper border-outline mb-5  py-5 d-sm-flex justify-content-start">
              <div class="row px-2">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field class="form-control" v-model="search.name" label="Name, phone, email, symbol no." outlined
                    clearable @input="search.name = $event !== null ? $event : ''" v-on:keyup.enter="getUsers()" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select :items="academicYears" class="form-control" :menu-props="{ button: true, offsetY: true }"
                    v-model="search.academic_year_id" label="Academic year" item-value="id" item-text="year"
                    @input="search.academic_year_id = $event !== null ? $event : ''" outlined
                    v-on:keyup.enter="getUsers()" clearable dense>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select :items="levels" :menu-props="{ button: true, offsetY: true }" class="form-control"
                    v-model="search.level_id" label="Level" item-value="id" item-text="title"
                    @input="search.level_id = $event !== null ? $event : ''" outlined v-on:keyup.enter="getUsers()"
                    @change="getPrograms" clearable dense>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select outlined dense v-model="search.program_id" :items="programs" label="Programs"
                    @input="search.program_id = $event !== null ? $event : ''" item-value="id"
                    :menu-props="{ button: true, offsetY: true }" item-text="title" @change="getGrades"
                    v-on:keyup.enter="getUsers()" clearable>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select outlined dense v-model="search.grade_id" :items="gradesLevels"
                    @input="search.grade_id = $event !== null ? $event : ''" label="Semester/year"
                    :menu-props="{ button: true, offsetY: true }" item-value="id" item-text="title"
                    v-on:keyup.enter="getUsers()" @change="getAcademicClasses" clearable></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select outlined dense class="form-control" @input="search.class_id = $event !== null ? $event : ''"
                    v-model="search.class_id" :items="academic_classes" :menu-props="{ button: true, offsetY: true }"
                    label="Class name" item-value="id" v-on:keyup.enter="getUsers()" item-text="title"
                    clearable></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select outlined dense class="form-control"
                    clearable
                    @input="search.enable_library = $event !== null ? $event : ''" v-model="search.enable_library"
                    :items="library_status" :menu-props="{ button: true, offsetY: true }" label="Library status"
                    item-value="value" v-on:keyup.enter="getUsers()" item-text="text" ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select outlined dense class="form-control"
                            @input="search.is_active = $event !== null ? $event : ''" v-model="search.is_active"
                            :items="library_status" :menu-props="{ button: true, offsetY: true }" label="Status"
                            item-value="value" v-on:keyup.enter="getUsers()" item-text="text" ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select outlined dense v-model="search.sort_by" :items="sort_by"
                    :menu-props="{ button: true, offsetY: true }" label="Sort by" v-on:keyup.enter="getUsers()"
                    item-value="value" @input="search.sort_by = $event !== null ? $event : ''" item-text="title"
                    clearable></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select outlined dense v-model="search.limit" :items="limits"
                    :menu-props="{ button: true, offsetY: true }" label="Limit" v-on:keyup.enter="getUsers()"
                    item-value="value" item-text="title" @input="search.limit = $event !== null ? $event : ''"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="text-right">
                  <v-btn :loading="loading" @click.prevent="getUsers()" class="btn btn-primary w-35 float-right" dark>
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="isLoading" type="table-row-divider@25">
              </v-skeleton-loader>
              <v-row v-if="import_error.length > 0">
                <div class="col-12">
                  <b-list-group>
                    <b-list-group-item variant="danger">
                      <div class="row" v-for="(error, index) of import_error" :key="index">
                        <div class="col-4">
                          <strong>{{ error.user_name }}</strong>
                          <br>
                          <strong>{{ error.email }}</strong>
                        </div>
                        <div class="col-8">
                          <ul class="">
                            <li v-for="(item, i) of error.issues.split(',')" :key="i" v-show="item">
                              <small>{{ item }}</small>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </v-row>
              <v-row>
                <v-col cols="12" v-if="selected.length > 0">
                  <v-card flat color="grey lighten-4">
                    <v-toolbar flat color="grey lighten-4">
                      <v-toolbar-title>Selected: {{ selected.length }}</v-toolbar-title>
                      <v-toolbar-title class="ml-5"> Actions:&nbsp;</v-toolbar-title>
                      <v-btn class="text-white ml-2 btn btn-primary" depressed @click.prevent="openChangePassDialog()">
                        <v-icon>assignment_ind</v-icon>
                        Change password
                      </v-btn>
                      <v-btn class="text-white ml-2 btn btn-primary" depressed @click.prevent="openChangeParentPasswordDialog()">
                        <v-icon>mdi-account-key</v-icon>
                        Change password for parent
                      </v-btn>
                    </v-toolbar>
                  </v-card>

                </v-col>
              </v-row>

              <table class="table" v-if="!isLoading">
                <thead>
                  <tr class="">
                    <th class="" style="width: 40px !important; white-space: pre-wrap;">
                      <v-checkbox class="id-card__checkbox" @change="selectAll()" v-model="checkAll"></v-checkbox>
                    </th>
                    <th class="" colspan="2" style="width: 450px !important; white-space: pre-wrap;">Student info</th>
                    <th class="" style="width: 500px !important; white-space: pre-wrap;">Academic</th>
                    <th class="pr-3 text-center" style="width: 70px !important; white-space: pre-wrap;"></th>
                  </tr>
                </thead>
                <template v-if="users.length > 0">
                  <tr v-for="item in users" :key="item.id">
                    <td style="width: 40px !important; white-space: pre-wrap;">
                      <v-checkbox v-model="selected" :value="item"></v-checkbox>
                    </td>

                    <td class="text-center" style="width: 40px !important; white-space: pre-wrap;">
                      <div class="symbol-label mt-2">
                        <img v-if="item.image_path && item.image_path.real" :src="item.image_path.real"
                          class="cursor-pointer" alt="" @click="changeImage(item)" style="height: 30px;">
                        <span v-else class="symbol symbol-35 symbol-light-success" @click="changeImage(item)">
                          <span class="symbol-label font-size-sm cursor-pointer">
                            {{ item.first_name ? item.first_name.charAt(0).toUpperCase() : '' }}
                            {{ item.last_name ? item.last_name.charAt(0).toUpperCase() : '' }}</span>
                        </span>

                      </div>
                    </td>
                    <td>
                      <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                      {{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
                      </a>|
                      <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                        <b> {{ item.personal_id }} </b> <i class="fa fa-book-open font-size-xs"></i>
                        <span class="badge-dot" v-bind:class="{ 'bg-color-green': item.is_active, 'bg-color-red': !item.is_active }">
                        </span><br />
                      </a>
                      <span class="text-secondary" v-if="item.setting && item.setting.midas_id">
                        <b> External ID:</b> {{ item.setting.midas_id != null ? item.setting.midas_id : 'N/A' }}
                      </span>
                    </td>

                    <td style="width: 500px !important; white-space: pre-wrap;">
                      <span class="text-secondary">
                        <simplebold><a :href="`#/program/update/${item.setting.program_id}`">{{ item.program_short_title  ? item.program_short_title : "N/A" }}</a></simplebold> |
                        <simplebold><a :href="`#/program/update/${item.setting.program_id}`">{{ item.setting && item.setting.current_semester_title ? item.setting.current_semester_title : "N/A" }}</a></simplebold> <br/>
                        <simplebold><a :href="`#/${item.setting.class_id}/summary`">{{ item.setting && item.setting.current_class_title ? item.setting.current_class_title : "N/A" }}</a></simplebold> |
                        <simplebold v-if="item.setting && item.setting.academic_year"><a href="#/academic-year">{{ item.setting.academic_year != null ? item.setting.academic_year : 'N/A' }}</a></simplebold>
                      </span>
                    </td>
                    <td class="text-center"  style="width: 70px !important; white-space: pre-wrap;">
                      <template v-if="checkIsAccessible('user', 'edit') || checkIsAccessible('user', 'delete')">
                        <b-dropdown size="sm" variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                          right no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'edit')">
                              <a class="navi-link" @click="openSummaryPage(item)">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text v-if="checkIsAccessible('user', 'delete')" tag="div" class="navi-item">
                              <a class="navi-link" @click="deleteUser(item.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-if="users.length == 0">
                  <tr>
                    <td class=" text-center" colspan="8">
                      <strong>No data available.</strong>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination v-if="users.length > 0" class="pull-right mt-7" @input="getUsers" v-model="page"
                :total-rows="total" :per-page="perPage" first-number :loading="isLoading" last-number></b-pagination>
            </div>
          </div>
        </div>
        <v-dialog v-model="changePasswordDialog" max-width="600">
          <v-card>
            <v-toolbar dark>
              <v-card-title class="headline">
                Change bulk password
                <hr>
              </v-card-title>

              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon @click="hideDialog">
                  <i class="fas fa-times"></i>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field  :error="$v.password.$error" outlined v-model="password" dense label="Password">

                  </v-text-field>
                  <span class="text-danger" v-if="!$v.password.minLength">Password should be more 8 character</span>
                </v-col>
                <v-col cols="12">
                  <v-switch outlined v-model="is_send_mail" dense label="Send mail to students">

                  </v-switch>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed class="btn btn-standard cancel-btn" @click="hideDialog">Cancel</v-btn>
              <v-btn depressed :loading="changeLoading" class="btn btn-primary text-white ml-2"
                @click.prevent="changeBulkPassword">Change password
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="changeParentPasswordDialog" max-width="600">
          <v-card>
            <v-toolbar dark>
              <v-card-title class="headline">
                Change bulk password for parent
                <hr>
              </v-card-title>

              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon @click="hideParentPasswordDialog">
                  <i class="fas fa-times"></i>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-text-field :error="$v.password.$error" outlined v-model="password" dense label="Password">

              </v-text-field>
              <span class="text-danger" v-if="!$v.password.minLength">Password should be more 8 character</span>
            </v-card-text>
            <v-card-actions>
              <v-col cols="12">
                <div class="float-right">
                  <v-btn depressed class="btn btn-standard cancel-btn" @click="hideParentPasswordDialog">Cancel</v-btn>
                  <v-btn depressed :loading="changeLoading" class="btn btn-primary text-white ml-2"
                         @click.prevent="changeBulkParentPassword">Change password
                  </v-btn>
                </div>
              </v-col>


            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="uploadDialog" max-width="800">
          <v-card>
            <v-card-title >
              Update profile
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <h4>Personal information</h4> <hr/>
                </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.first_name" outlined dense label="First name"></v-text-field>
                    <span class="text-danger" v-if="$v.selected_user.first_name.$error">First name is required</span>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.middle_name" outlined dense label="Middle name"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.last_name" outlined dense label="Last name"></v-text-field>
                    <span class="text-danger" v-if="$v.selected_user.last_name.$error">Last name is required</span>
                  </v-col>
                <v-col cols="12">
                  <h4>Contact information</h4> <hr/>
                </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.mobile" outlined dense label="Contact mobile"></v-text-field>
                    <span class="text-danger" v-if="$v.selected_user.mobile.$error">Mobile is required</span>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.phone" outlined dense label="Contact phone"></v-text-field>
                  </v-col>
                <v-col cols="12">
                  <h4>Identity Card information</h4> <hr/>
                </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.setting.id_card_address" outlined dense
                      label="ID card address"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input @change="changePreviewImage" :rules="rules" v-model="user.photo" outlined
                      prepend-icon="" @click:clear="previewImage = null" dense accept="image/png, image/jpeg, image/bmp"
                      prepend-inner-icon="mdi-camera" label="Image"></v-file-input>
                  </v-col>
                  <v-col cols="4" v-if="user">
                    <div class="symbol-label">
                      Image preview<br/>
                      <img v-if="previewImage" :src="previewImage" class="" alt="" style="height: 150px; width: 200px; object-fit:contain;">
                      <span v-else class="symbol  symbol-light-success ">
                        <span class="symbol-label font-size-h6 font-weight-bold p-5">
                          NA</span>
                      </span>
                    </div>
                  </v-col>

              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="12">
                <div class="float-right">
                  <v-btn @click="cancelAction" class="btn btn-standard cancel-btn" depressed>Cancel
                  </v-btn>
                  <v-btn class="text-white ml-2 btn btn-primary" depressed @click="updateStudent" :loading="isBusy">Update
                  </v-btn>
                </div>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import {API_URL} from "@/core/config";
import {minLength, required} from "vuelidate/lib/validators";
import UserParentService from "@/core/services/user/UserParentService";

const userService = new UserService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService();
const userParent = new UserParentService()
export default {
  name: "users",
  components: {},
  validations: {
    selected_user: {
      last_name: { required },
      first_name: { required },
      mobile: { required },
    },
    password: { required, minLength: minLength(8)}
  },
  data() {
    return {
      import_error: [],
      passwordData: [],
      selected: [],
      loading: false,
      changeLoading: false,
      changePasswordDialog: false,
      changeParentPasswordDialog: false,
      is_send_mail: false,
      sort_by: [
        { value: "ASC", title: "A-Z" },
        { value: "DESC", title: "Z-A" },
        { value: "new", title: "Recently added" },
      ],
      limits: [
        { value: "25", title: "25" },
        { value: "50", title: "50" },
        { value: "75", title: "75" },
        { value: "100", title: "100" },
        { value: "250", title: "250" }
      ],
      headers: [
        {
          text: 'Image',
          align: 'start',
          filterable: false,
          sortable: false,
          value: 'thumbnail'
        },
        { text: 'CRN', value: 'personal_id', filterable: true, sortable: true },
        { text: 'First name', value: 'first_name', filterable: true, sortable: true },
        { text: 'Middle name', value: 'middle_name', filterable: true, sortable: true },
        { text: 'Last name', value: 'last_name', filterable: true, sortable: true },
        { text: 'Program', value: 'setting.program_title', filterable: true, sortable: true },
        { text: 'Status', value: 'is_active', filterable: false, sortable: true },
        { text: 'Action', value: 'action', filterable: false, sortable: false },
      ],
      import_excel: false,
      isLoading: false,
      isBusy: false,
      uploadDialog: false,
      searchKeywords: '',
      password: null,
      previewImage: null,
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      excel_file: null,
      user: {
        photo: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        mobile: null,
        phone: null,
        setting: {}
      },
      selected_user: {
        photo: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        mobile: null,
        phone: null,
        setting: {}
      },
      search: {
        name: '',
        type: 'student',
        limit: 25,
        enable_library:'',
        is_active:1,

      },
      users: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      library_status: [
        { text: 'All', value: '' },
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 0 },
      ],
      levels: [],
      levelId: null,
      page: null,
      total: 0,
      totalPage: null,
      perPage: 25,
      checkAll: false,
    };
  },
  mounted() {
    if (this.$route.query.symbol_no || this.$route.query.name) {
      this.search = this.$route.query;
    }
    this.getUsers();
    this.getLevels()
    this.getAcademicYears()
  },
  methods: {
    goToClass() {
      this.$router.push({name: "academic-class"});
    },
    resetFilter() {
      this.search = {
        name: '',
        type: 'student',
        limit: 25,
      }
      this.getUsers();
    },
    getUsers() {
      this.users = []
      this.isLoading = true;
      userService.paginate(this.search, this.page).then(response => {
        this.isLoading = false;
        this.users = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.totalPage = response.data.meta.last_page;
        this.perPage = parseInt(response.data.meta.per_page);
        this.loading = false
        // alert(this.length)
      });
    },
    test(e) {
      this.page = e;
      this.getUsers();
    },
    openChangePassDialog() {
      this.changePasswordDialog = true;
    },
    openChangeParentPasswordDialog() {
      this.changeParentPasswordDialog = true;
    },
    hideDialog() {
      this.password = null;
      this.is_send_mail = false;
      this.changePasswordDialog = false;
    },
    hideParentPasswordDialog() {
      this.password = null;
      this.is_send_mail = false;
      this.changeParentPasswordDialog = false;
    },
    cancelAction() {
      this.uploadDialog = false;
      this.resetUploadDialogData();
    },
    closeUploadDialog() {
      this.uploadDialog = false;
      this.resetUploadDialogData();
      this.getUsers();
    },
    resetUploadDialogData() {
      this.selected_user = {
        photo: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        mobile: null,
        phone: null,
        setting: {}
      };
      this.previewImage = null;
    },
    updateStudent() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000)
        return
      }
      delete this.user.setting
      let fd = this.generateFd();
      this.isBusy = true;
      userService
        .update(this.selected_user.id, this.selected_user)
        .then(response => {

          if (this.user.photo != null) {
            this.uploadImage();

          } else {
            this.$snotify.success("Information updated");
            this.resetUploadDialogData();
            this.getUsers();
          }
          this.uploadDialog = false;
          this.isBusy = false;


        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
    convertToFormData() {
      let formData = new FormData();
      let upload_image = {};
      upload_image.photo = this.user.photo;
      for (let key in upload_image) {
        if (key === "photo" && upload_image[key] != null && upload_image[key] != undefined) {
          formData.append('photo', upload_image[key]);
        } else {
          if (upload_image[key]) {
            formData.append(key, upload_image[key]);
          }
        }
      }
      return formData;
    },
    uploadImage() {
      let formData = this.convertToFormData();
      userService
        .update(this.selected_user.id, formData)
        .then(response => {

          this.$snotify.success("Information updated");
          this.resetUploadDialogData();
          this.getUsers();
        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
    changePreviewImage() {
      this.previewImage = URL.createObjectURL(this.user.photo)
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.user) {
        if (
          key == "photo" &&
          this.user["photo"] &&
          this.user["photo"] != null
        ) {
          if (typeof this.user["photo"] == "object") {
            fd.append("photo", this.user[key]);
          }
        } else if (key == "notification_email" && this.user["notification_email"]) {
          fd.append("notification_email", this.user.notification_email ? 1 : 0);
        } else if (key == "notification_sms") {
          fd.append("notification_sms", this.user.notification_sms ? 1 : 0);
        } else if (key == "notify_parent") {
          fd.append("notify_parent", this.user.notify_parent ? 1 : 0);
        } else if (key == "com_pref_postal") {
          fd.append("com_pref_postal", this.user.com_pref_postal ? 1 : 0);
        } else if (key == "notify_other") {
          fd.append("notify_other", this.user.notify_other ? 1 : 0);
        } else {
          if (key != "id" && this.user[key]) {
            fd.append(key, this.user[key]);
          }
        }
      }
      return fd;
    },

    editUser(id) {
      return this.$router.push({ name: "students-update", params: { id: id } });
    },
    changeImage(user) {
      this.selected_user = user;

      if(user && user.image_path){
        this.previewImage = user.image_path['thumb'];
      }

      if (user && user.setting) {
        this.selected_user.setting.id_card_address = user.setting.id_card_address
      }

      this.uploadDialog = true;
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.search.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    changeBulkPassword() {
      this.$v.password.$touch();
      if (this.$v.password.$error) {
        setTimeout(() => {
          this.$v.password.$reset();
        }, 3000);
      } else {
        this.buildPasswordData();
        this.changeLoading = true;
        userService.bulkUpdatePassword({users: this.passwordData, is_send_mail: this.is_send_mail}).then(response => {
          this.$snotify.success("Bulk password updated")
        }).catch(() => {
          this.$snotify.error("Error occurred")
        }).finally(() => {
          this.changeLoading = false;
          this.hideDialog();

        })
      }
    },
    changeBulkParentPassword() {
      this.$v.password.$touch();
      if (this.$v.password.$error) {
        setTimeout(() => {
          this.$v.password.$reset();
        }, 3000);
      } else {
        this.buildPasswordData();
        this.changeLoading = true;
        userParent.bulkUpdatePassword(this.passwordData).then(response => {
          if(response.data.status == "OK") {
            this.$snotify.success("Bulk password updated")
          }
        }).catch(error => {
          this.$snotify.error("Error occurred")
        }).finally(() => {
          this.changeLoading = false;
          this.hideParentPasswordDialog();
        })
      }
    },
    buildPasswordData() {
      if (this.selected.length) {
        this.passwordData = this.selected.map(user => {
          return {
            id: user.id,
            email: user.email,
            password: this.password,
            first_name: user.first_name,
          };
        })
      }
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;

      });
    },
    getAcademicClasses() {
      academicClassService.getByProgramLevel(this.search.academic_year_id, this.search.program_id, this.search.grade_id).then(response => {
        this.academic_classes = response.data;
      });
    },
    deleteUser(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
              .delete(id)
              .then(response => {
                this.$snotify.success("Information deleted");
                this.getUsers();
              })
              .catch(error => {
                //console.log(error);
              });
          }
        }
      });
    },
    manageUserRole(id) {
      this.$router.push({ name: "user-role", params: { id: id } });
    },
    createStudent() {
      this.$router.push({
        name: "students-create",

      });
    },
    openSummaryPage(item) {
      this.$router.push({
        name: "students-summary",
        params: { id: item.id, type: item.type[0] }
      });
    },
    selectAll() {
      if (!this.checkAll) {
        this.selected = [];
      } else {
        this.users.forEach(user => {
          this.selected.push(user);
        })

      }
    },
    cancelStudentImport(){
      this.import_excel = false;
    },
    importStudent() {
      this.isLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      this.import_error = [];
      userService.importUser(fd).then(res => {
        this.import_error = res.data
        this.isLoading = false
        this.excel_file = null;
        this.import_excel = false;
        this.$snotify.success("File import success");
        this.getUsers();
      }).catch(() => {
        this.isLoading = false;
        this.$snotify.error("File import Failed");
      }).finally(() => {
        this.isLoading = false;
      });
    },

    exportData() {
      let url = API_URL + "user/student-export-csv"
      if (this.search) {
        if (this.search.limit) {
          delete this.search.limit
        }
        let query = new URLSearchParams(this.search).toString();
        if (query)
          url = url + '?' + query;
      }
      window.open(url)
    },

    exportParentDetails() {
      let url = API_URL + "user/student/export/parent-details"
      if (this.search) {
        if (this.search.limit) {
          delete this.search.limit
        }
        let query = new URLSearchParams(this.search).toString();
        if (query)
          url = url + '?' + query;
      }
      window.open(url)
    }
  }
};
</script>

<style scoped>
.form-control {
  border: none !important;
}
</style>
